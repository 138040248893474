import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'

const ImagesGallery = () => {
    const data = useStaticQuery(
        graphql`
  query ImagesForGallery {
    allFile (
        filter: { relativeDirectory: { eq: "gallery" } }
        sort: { fields: name }
        ){
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
    )
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  // `images` is an array of objects with `thumb` and `full`
  return <Gallery images={images} />
}

export default ImagesGallery