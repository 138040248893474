  
import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import * as L from 'leaflet'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import './leafletmap.css'


class LeafletMap extends React.Component {
  constructor(props){
    super(props);
    this.mapRef = React.createRef();
  }

  static propTypes = {
    /** Latitude and Longitude of the map centre in an array, eg [51, -1] **/
    position: PropTypes.array,

    /** Initial zoom level for the map (default 13) **/
    zoom: PropTypes.number,

    /** If set, will display a marker, which when clicked will display this text **/
    markerText: PropTypes.string
  }

  static defaultProps = {
    position: [51, -1],
    zoom: 13,
    markerText: ""
  }

  onMapReady = () => { const map = this.mapRef.current.leafletElement;
     setTimeout(() => { 
       map.invalidateSize(); 
   }, 600);  
}
  render() {

  const customMarker = new L.icon({
  iconUrl: '../images/gatsby-icon.png',
  iconSize: [56, 72],
  iconAnchor: [26, 72],
}); 

const MyPopupMarker = ({ content, position }) => (
  <Marker position={position} >
    <Popup>{content}</Popup>
  </Marker>
)

 const MyMarkersList = ({ markers }) => {
  const items = markers.map(({ key, ...props }) => (
      <MyPopupMarker key={key} {...props} />
  ))
  return <Fragment>{items}</Fragment>
}

    const markers = [
      { key: 'Gen', position: [41.7070953,12.6906063], content: 'Botte Gelata - Genzano' },
      { key: 'marker2', position: [41.6911611,12.7783001], content: 'Botte Gelata - Velletri' },
    ]

      return (
        <Map center={this.props.position} zoom={this.props.zoom} ref={this.mapRef} whenReady={this.onMapReady}>
          <TileLayer
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          />
          {//this.props.markerText !== "" &&
          // <Marker position={[(41.7070953,12.6906063), (41.6911611,12.7783001) ]}>
          //   <Popup>{this.props.markerText}</Popup>
          // </Marker>
          // {markers.map(marker => 
          //   <Marker key={marker.key} position={marker.position}>
          //   <Popup>
          //     <span>{marker.content}</span>
          //   </Popup>
          // </Marker>
          // )}
          <MyMarkersList markers={markers} />
          }
        </Map>
      );
  }
}

export default LeafletMap