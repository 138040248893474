import PropTypes from 'prop-types'
import React from 'react'
import LeafletMap from "./leaflet-map"
import ImagesGallery from './Gallery'


class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Gallery</h2>
          <ImagesGallery />
          {close}
        </article>
        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >  
          <h2 className="major">Contatti</h2>
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
            gridGap: '5px',
            justifyItems: 'center'}}
            >
              <div>
                <h3>La Botte Gelata - GENZANO</h3>
                <p>
                Via Italo Belardi, 67 
                </p>
                <p>
                00045 Genzano di Roma RM
                </p>
                <p>
                06 936 4842
                </p>
                <ul className="icons">
                  <li>
                    <a target="_blank" href="https://www.facebook.com/La-Botte-Gelata-105971590776899/" className="icon fa-facebook">
                      <span className="label">Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/la.botte.gelata__genzano.di.rm/?hl=it" className="icon fa-instagram">
                      <span className="label">Instagram</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href='https://www.google.it/maps/place/La+Botte+Gelata/@41.7070993,12.6884176,17z/data=!3m1!4b1!4m5!3m4!1s0x132584bf8f8081fb:0x2ff2a033875b0ea4!8m2!3d41.7070672!4d12.690598' className="icon fa-location-arrow">
                      <span className="label">Indicazioni</span>
                   </a>
                </li>
                </ul>
              </div>
              <div>
                <h3>La Botte Gelata - VELLETRI</h3>
                <p>
                Via Pia, 4
                </p>
                <p>
                00049 Velletri RM
                </p>
                <p>
                350 528 4350
                </p>
                <ul className="icons">
                  {/* <li>
                    <a href="https://www.facebook.com/La-Botte-Gelata-105971590776899/" className="icon fa-facebook">
                      <span className="label">Facebook</span>
                    </a>
                  </li> */}
                  <li>
                    <a target="_blank" href="https://www.instagram.com/la.botte.gelata__velletri/" className="icon fa-instagram">
                      <span className="label">Instagram</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href='https://www.google.it/maps/place/La+Botte+Gelata+-+Velletri/@41.6912539,12.7761796,17z/data=!3m1!4b1!4m5!3m4!1s0x132583d117525047:0xa707e7541bb29d3!8m2!3d41.6912125!4d12.7783869' className="icon fa-location-arrow">
                      <span className="label">Indicazioni</span>
                   </a>
                </li>
                </ul>
              </div>

            </div>
          {/* <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form> */}
                      {typeof window !== 'undefined' && this.props.isContactOpen &&
          <LeafletMap 
            position={[41.6911611,12.7383001]}
            zoom={12}
            markerText={"La Botte Gelata - Velletri"}
          />
        }
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
