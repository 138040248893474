import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/logo.png' 

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      {/* <span className="icon fa-diamond"></span> */}
      <img src={logo} alt="Logo"  style={{
        'maxWidth': '100%',
        'maxHeight': '100%',
        }}/>
    </div>
    <div className="content">
      <div className="inner">
        <h1>La Botte Gelata</h1>
        <p>
        Gelateria artigianale ai Castelli Romani
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Gallery
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contatti
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
